@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Nunito:wght@400;500;600;700;800;900;1000&display=swap');

* {
    box-sizing: border-box;
}

body {
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    background: #edf1f5;
}

.mainContainer {
    height: 94vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.MuiButtonBase-root {
    font-family: "Montserrat", sans-serif !important;
    text-transform: capitalize !important;
    border-radius: 20px !important;
}

.MuiFormLabel-root, .MuiFormControlLabel-label, .MuiTypography-root {
    font-family: Montserrat, sans-serif !important;
}

.MuiInputBase-root {
    font-family: Montserrat, sans-serif !important;
    border-radius: 30px !important;
}

.MuiTooltip-tooltip {
    font-family: "Montserrat", sans-serif !important;
    background: black !important;
    font-size: 13px !important;
    border-radius: 15px !important;
    max-width: none !important;
}

.swal2-toast {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px !important;
}

.swal2-styled.swal2-confirm, .swal2-styled.swal2-cancel {
    border-radius: 30px !important;
    padding: 8px 30px !important;
}

