.main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.innerMain {
    width: 450px;
}

.form {
    background: #ffffff;
    box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
    border-radius: 18px;
    padding: 2rem 2rem;
}

@media screen and (max-width: 500px) {

    .innerMain {
        width: 100%;
    }
}

