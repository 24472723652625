.sessions, .sessionItems {
    list-style: none;
    padding: 0;
}

.sessions {
    position: relative;
}

.sessionItems {
    padding-bottom: 1.5rem;
    border-left: 1px solid #abaaed;
    position: relative;
    padding-left: 20px;
    margin-left: 10px;
}

.sessionItems:last-child {
    border: 0;
    padding-bottom: 0;
}

.sessionItems:before {
    content: "";
    width: 15px;
    height: 15px;
    background: white;
    border: 1px solid #4e5ed3;
    box-shadow: 3px 3px 0 #bab5f8;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 0;
}